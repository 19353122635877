@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  position: relative;
  display: flex;
  overflow: hidden;
  max-width: 22.5rem;
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  padding: $spacing-sm;
  border-radius: 0.5rem;
  text-align: left;

  @include screen-size('small') {
    max-width: 35.125rem;
  }

  @include screen-size('large') {
    max-width: 44rem;
    padding: $spacing-lg;
  }

  &:hover {
    text-decoration: none;
  }

  .cta-logo {
    display: none;
  }

  &.has-background {
    &::after {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $background-overlay-image;
      content: '';
    }
  }

  &[data-theme='dark'] {
    color: $label-primary-dark;
  }

  &[data-theme='light'] {
    color: $label-primary-light;
  }

  &[data-partner='insurance'] {
    background: linear-gradient(180deg, #7a3f9d 0%, #590f85 50.5%, #2b173f 100%);
  }

  &[data-partner='sellYourVehicle'] {
    background-color: $motorwayYellow;
  }
}

.title {
  @include text-md;
  @include text-bold;

  margin-bottom: 0;

  @include screen-size('large') {
    @include display-md;

    [data-partner='insurance'] & {
      padding-right: 7rem;
    }
  }
}

.title,
.paragraph {
  &.normal {
    display: none;
  }

  @include screen-size('small') {
    &.short {
      display: none;
    }

    &.normal {
      display: block;
    }
  }
}

.paragraph {
  @include text-md;
  @include text-normal;
}

.header-logo {
  @include screen-size('large') {
    &[data-is-visible='true'] {
      display: block;
      margin: 0 0 $spacing-sm;
    }
  }

  display: none;
  margin: 0;
}

.cta-logo {
  display: inline-flex;
}

.header-logo,
.cta-logo,
.content,
.cta {
  z-index: 10;
}

.content,
.title,
.paragraph {
  color: inherit;
}

.content {
  display: grid;
  max-width: 47.25rem;
  gap: $spacing-xs;

  @include screen-size('large') {
    padding-right: 12rem;
  }

  @include screen-size('large') {
    padding-right: 5.5rem;
  }
}

.cta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: $spacing-sm;
  gap: $spacing-xs;
}

.background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  inset: 0;

  &.large {
    display: none;
  }

  @include screen-size('large') {
    &.large {
      display: block;
    }
  }

  span,
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center;
  }
}

.sellYourVehicle {
  position: absolute;
  top: 0;
  right: -1rem;
  bottom: 0;
  max-width: 209px;
  min-height: 100%;
  object-fit: contain;

  img,
  span {
    min-height: 100%;
    object-fit: cover;
  }

  @include screen-size('large') {
    right: 0;
    max-width: unset;
  }
}

.insurance {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  display: none;
  width: 149px;
  height: 96px;
  object-fit: contain;

  img,
  span {
    object-fit: contain;
  }

  @include screen-size('large') {
    display: block;
  }
}

.external-button {
  @media (hover) and (pointer: fine) {
    &:hover {
      background-color: mix($fill-base, $background-surface, 12%);
      color: $label-primary-light;
    }
  }

  @include text-sm;
  @include text-bold;

  padding: 0.625rem 1rem;
  border-radius: 6.25rem;
  background-color: $background-surface;
  color: $label-primary-light;
  transition: all 0.2s ease-in-out;

  [data-partner='insurance'] & {
    color: $moneySuperMarketPurple;
  }

  &.has-icon {
    padding-right: 0.75rem;
    padding-left: 1rem;
  }

  .button-spacing {
    margin-left: 0.38rem;
  }
}

.in-partnership-with {
  @include text-xs;
  @include text-normal;

  display: grid;
  margin-left: $spacing-xs;
  gap: $spacing-xxs;
}
